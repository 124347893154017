body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
    background-color: #EFEFEF;
}

*,
body,
html {
  box-sizing: border-box;
}

#main-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#content-container {
  display: flex;
  flex-grow: 1;
  margin-top: 20vh;

  width: 100%;
  justify-content: center;
  align-items: flex-start;
}

.section {
  border-radius: 60px;
  width: 90vw;
  margin: 5vh auto;
  align-items: center;
}

.section h1 {
  font-weight: bolder;
}
