#navbar {
  display: flex;
  position: fixed;
  align-items: center;
  width: 70%;
  justify-content: center;
  min-height: 10vh;
  backdrop-filter: blur(20px);
  background-color: #ffffffb0;
  border-radius: 40px;
  z-index: 100;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

#navbar a {
  font-weight: bold;
  text-decoration: none;
  color: gray;
  font-size: 25px;
}

#navbar__links {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
}

#navbar__links li a.active,
#navbar__links li a:not(.active):hover {
  color: black;
}


@media (max-width: 768px) {
  #navbar {
    border-radius: 0;
    width: 100%;
    top: 0;
  }
}