@keyframes waveRotate {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  25% {
    transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
  }

  50% {
    transform: rotate(-12deg);
    -webkit-transform: rotate(-12deg);
  }

  100% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

#home-container .wave-animation {
  transition: all 0.3s;
  transform-origin: bottom center;
  font-size: 70px;
  animation: waveRotate 2s ease-in-out;
}

#home-container {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

#home-container .colored-text {
  color: #03465c;
}

#home-container .colored-background {
  background-color: #03465c;
  border-radius: 5px;
  border: 6px solid transparent;
  color: white;
  line-height: 2;
}


#home-container h1 {
  margin: 10px;
}

.section__projects {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  gap: 1em;
}

.section__projects h1 {
  grid-column-end: span 12;
}

@media (max-width: 800px) {
  #animated-text {
    height: 3.5em;
  }
}