.section__contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  background-color: white;
}

.section__contact label {
  font-weight: bold;
}

.section__contact form {
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.section__contact form input, .section__contact form textarea {
  margin: 5px;
  padding: 15px;
  width: 90%;
  text-align: center;
  background-color: transparent;
  color: black;
  min-width: 200px;
  border: 2px black solid;
  border-radius: 10px;
  transition: all 0.3s ease;
  resize: none;
  text-align: left;

}


.section__contact form button {
  margin-top: 20px;
  padding: 15px 40px;
  border: none;
  background-color: black;
  font-size: 15px;
  font-weight: bolder;
  color: white;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .section__contact form input, .section__contact form textarea {
    width: 30%;
  }
}