.section__about {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  background-color: white;
}

.section__about p {
  font-size: x-large;
  margin: 0 10% 5%;
}
