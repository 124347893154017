.project-card {
  color: black;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  grid-column-start: auto;
  grid-column-end: span 4;
  height: 100%;
}

.project-card h1 {
  font-size: 45px;
  font-weight: bold;
}

.project-card p {
  font-size: large;
  margin: 1vw;
}

.project-card a {
  background-color: #03465c;
  color: white;
  font-size: 25px;
  border-radius: 9px;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: auto;
}

.project-card a:hover {
  transition: all 0.3s ease;
  font-size: 27px;
}

@media (max-width: 1100px) {
  .project-card {
    grid-column-end: span 12;
  }

  .project-card:nth-child(2),
  .project-card:nth-child(3) {
    grid-column-start: auto;
  }
}